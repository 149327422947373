import BurgerIcon from "../../assets/media/burger.svg";

function Burger({ openMobileNavigation }) {
    return (
        <div className={"menu-box burger-button"}>
            <img src={BurgerIcon} alt="" onClick={openMobileNavigation}/>
        </div>
    )
}

export default Burger;