import Separator from "./Separator";
import { useTranslation } from "react-i18next";
import { useData } from '../../DataContext';

import ExternalLink from "../../assets/media/external-link.svg";

function Footer() {
    const data = useData();
    const { t } = useTranslation();
    const scrollToTop = () => window.scrollTo({ top: 0, behavior: "smooth" });
    
    return (
        <footer>
            <div className={"copyright"}>
                <p>{t('footer.copyright')} © 2024</p>
                <Separator/>
            </div>
            
            <div className={"project"}>
                <p>v{process.env.REACT_APP_VERSION}</p>
            </div>
            
            <div className={"scrollTop"}>
                <Separator/>
                <a onClick={scrollToTop}>{t('footer.scrollUp')}</a>
            </div>
            
        </footer>
    )
}

export default Footer;