function MenuTitle() {
    const scrollToTop = () => window.scrollTo({ top: 0, behavior: "smooth" });
    
    return (
        <div className={"menu-box"}>
            <h2 className={"title"} onClick={scrollToTop}>AB<span>.</span>Portfolio<span>();</span></h2>
        </div>
    )
}

export default MenuTitle;