import React, { useState } from 'react';
import Section from  '../common/Section';
import { useTranslation } from "react-i18next";
import ProjectCards from "./cards/ProjectCards";
import SectionContent from "../common/SectionContent";

function Projects() {
    const { t } = useTranslation();
    
    // Cards
    const [maxProjectDisplay, setMaxProjectDisplay] = useState(6);
    const showMoreProjects = () => setMaxProjectDisplay(maxProjectDisplay+6);
    
    return (
        <Section title={t('projects.title')} name={"projects"} details={"Voici divers projets que j'ai pu réaliser au fil du temps"}>
            <SectionContent>
                <ProjectCards
                    maxProjectDisplay={maxProjectDisplay}
                    showMoreProjects={showMoreProjects}/>
            </SectionContent>
        </Section>
    )
}

export default Projects;