function Menu({ name, children }) {
    return (
        <div className={"menu menu-" + name}>
            <div className={"wrapper"}>
                { children }
            </div>
        </div>
    )
}

export default Menu;