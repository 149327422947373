import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n
.use(initReactI18next)
.init({
    resources: {},
    lng: 'fr',
    interpolation: {
        escapeValue: false,
    },
});

export const loadLanguageFromJSON = async (lang) => {
    try {
        const response = await fetch(`/lang/${lang}.json`);
        const langData = await response.json();
        
        i18n.addResourceBundle(lang, 'translation', langData, true);
    } catch (error) {
        console.error(error);
    }
};

export default i18n;