import Navigation from "./Navigation";
import Contact from "./Contact";
import CloseIcon from "../../assets/media/close.svg";

function MobileMenu({ shown, closeMobileNavigation }) {
    return (
        <div className={shown ? "menu-mobile-wrapper shown" : "menu-mobile-wrapper"}>
            {shown && <div className={"overlay"} onClick={closeMobileNavigation}></div>}
            <div className={"menu-mobile"}>
                <div className={"top"}>
                    <p>Navigation<span>();</span></p>
                    <img src={CloseIcon} alt="" onClick={closeMobileNavigation}/>
                </div>
                
                <Navigation closeMobileNavigation={closeMobileNavigation}/>
                
                <div className={"bottom"}>
                    <Contact/>
                </div>
            </div>
        </div>
    )
}

export default MobileMenu;