import Section from "../common/Section";
import { useTranslation } from "react-i18next";
import SectionContent from "../common/SectionContent";

function About() {
    const { t } = useTranslation();
    const paragraphs = t('about.text', { returnObjects: true });
    
    return (
        <Section name={"about"}>
            <SectionContent className={"presentation-wrapper"}>
                <SectionContent>
                    <div className={"presentation"}>
                        <p className={"tag"}>&lt;WelcomeMessage&gt;</p>
                        <div className={"text"}>
                            {paragraphs.map((paragraph, index) => (
                                <p key={index} dangerouslySetInnerHTML={{__html: paragraph}}/>
                            ))}
                        </div>
                        <p className={"tag"}>&lt;/WelcomeMessage&gt;</p>
                    </div>
                </SectionContent>
            </SectionContent>
        </Section>
    )
}

export default About;