import {useTranslation} from "react-i18next";

import HeaderButton from "./HeaderButton";
import ScrollArrow from "./ScrollArrow";
import Avatar from "./Avatar";
import Comment from "../common/Comment";

function Header() {
    const { t } = useTranslation();
    
    return (
        <div className="header" id={"page-header"}>
            <div className={"header-content"}>
                <Avatar />
                <div className={"text"}>
                    <div className={"title"}>
                        <h1 dangerouslySetInnerHTML={{__html: t("header.title")}}></h1>
                    </div>
                    <Comment name={"header"}
                             content={t("header.description")}
                             maxLines={10} />
                    <div className={"buttons"}>
                        <HeaderButton color={"green"} text={t("header.button.more")} destination={".about"}/>
                        <HeaderButton color={"violet"} text={t("header.button.projects")} destination={".projects"}/>
                    </div>
                </div>
            </div>
            <ScrollArrow />
        </div>
    )
}

export default Header;