import {useTranslation} from "react-i18next";
import TagIcon from "../../../assets/media/projects/tag.svg";
import Buttonlink from "./Buttonlink";

function ProjectCard({project}) {
    const { t } = useTranslation();
    
    let imageElement;
    
    try {
        const image = require(`../../../assets/media/project/${project.image}`);
        
        imageElement = <img src={image} alt="Thumbnail" className={"thumb"}/>;
    } catch (ignore) {
        imageElement = <img src="https://share.alkanife.dev/alkanife.png" alt="Thumbnail" className={"thumb"}/>
    }
    
    return (
        <div className={"card"}>
            {imageElement}
            <div className={"info"}>
                <h3>{ project.name }</h3>
                <p className={"description"}>{ project.description }</p>
                <div className={"tags"}>
                    <div className={"icon"}>
                        <img src={TagIcon} alt="Tag icon"/>
                    </div>
                    <p>
                        {
                            project.tags.map((tag) => tag).join(', ')
                        }
                    </p>
                </div>
                <div className={"links"}>
                    {
                        project.source && (
                            <Buttonlink
                                text={ t('projects.source') }
                                href={project.source}
                                external={false}
                                subclass="green" />
                        )
                    }
                    {
                        project.visit && (
                            <Buttonlink
                                text={ t('projects.visit') }
                                href={project.visit}
                                external={false}
                                subclass="violet" />
                        )
                    }
                </div>
            </div>
        </div>
    )
}

export default ProjectCard;