import { useEffect, useState } from "react";
import './assets/style/style.scss';
import { DataProvider } from "./DataContext";
import Pre from "./components/pre/Pre";
import { loadLanguageFromJSON } from "./i18n";
import Header from "./components/header/Header";
import About from "./components/about/About";
import Projects from "./components/projects/Projects";
import Footer from "./components/footer/Footer";
import HeadMenu from "./components/menu/HeadMenu";
import ScrollMenu from "./components/menu/ScrollMenu";
import Skills from "./components/skills/Skills";
import MobileMenu from "./components/menu/MobileMenu";

function App() {
    const [loadingState, setLoadingState] = useState('loadingJson');
    const [data, setData] = useState();
    
    useEffect(() => {
        const fetchData = async () => {
            try {
                document.body.classList.add("loading");
                
                const response = await fetch('/data.json');
                const jsonData = await response.json();
                
                await loadLanguageFromJSON('fr');
                await loadLanguageFromJSON('en');
                
                setData(jsonData);
                setLoadingState("loadingWebsite");
                
                await new Promise(resolve => setTimeout(resolve, 1000));
                
                setLoadingState("loadingFinished");
            } catch (error) {
                setLoadingState("loadingFailed");
                document.body.classList.remove("loading");
                console.error(error);
            }
        }
        
        fetchData();
    }, []);
    
    const [showingMobileNav, setShowingMobileNav] = useState(false);
    
    const openMobileNavigation = () => {
        setShowingMobileNav(true);
    }
    
    const closeMobileNavigation = () => {
        setShowingMobileNav(false);
    }
    
    return (
        <DataProvider data={data}>
            { loadingState.startsWith('loading') && <Pre loadingState={loadingState} setLoadingState={setLoadingState} /> }
            {
                (loadingState === 'loadingWebsite' || loadingState === 'loadingFinished' || loadingState === 'loaded') &&
                <div className={"homepage"}>
                    <HeadMenu openMobileNavigation={openMobileNavigation} />
                    <ScrollMenu openMobileNavigation={openMobileNavigation} closeMobileNavigation={closeMobileNavigation} />
                    <MobileMenu closeMobileNavigation={closeMobileNavigation} shown={showingMobileNav} />
                    <Header/>
                    <div className={"page-body"} id={'page-body'}>
                        <About/>
                        <Skills />
                        <Projects />
                    </div>
                    <Footer/>
                </div>
            }
        </DataProvider>
    )
}

export default App;