import Loader from "./Loader";
import Error from "./Error";

function Pre({ setLoadingState, loadingState }) {
    
    const handleTransitionEnd = () => {
        document.body.classList.remove("loading");
        setLoadingState("loaded");
    }
    
    return (
        <div className={`pre ${loadingState === 'loadingFinished' ? 'fade-out' : ''}`} onTransitionEnd={handleTransitionEnd}>
            {
                loadingState === 'loadingFailed' ? <Error /> : <Loader />
            }
        </div>
    )
}

export default Pre;