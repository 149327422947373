function Buttonlink(options) {
    let target = options.target || '_self';
    
    if (options.external)
        target = '_blank';
    
    let className = 'buttonlink';
    
    if (options.subclass !== undefined) {
        let subclasses = options.subclass.split(' ');
        
        for (const subclass of subclasses) {
            className += ' buttonlink-' + subclass;
        }
    }
    
    return <a href={options.href || null}
              target={target}
              className={className}>
        {options.text}
    </a>;
}

export default Buttonlink;