function Section({name, title, details, children}) {
    return (
        <div className={name ? 'section ' + name : 'section'} id={name + "-section"}>
            {title && <h2 className={"section-title"}><span>&#123;</span>{title}<span>&#125;</span></h2>}
            {details && <p className={"section-details"}>{details}</p>}
            {children}
        </div>
    )
}

export default Section;