import { scrollTo } from "../../utils";
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";

function Navigation({closeMobileNavigation}) {
    const { t } = useTranslation();
    const [activeSection, setActiveSection] = useState('');
    const [scrollSpyEnabled, setScrollSpyEnabled] = useState(true);
    
    useEffect(() => {
        const handleScroll = () => {
            if (!scrollSpyEnabled) return;
            
            const sections = document.querySelectorAll('.section');
            let currentSection = '';
            
            Array.from(sections).some(section => {
                const sectionTop = section.offsetTop - 200;
                const sectionHeight = section.clientHeight;
                
                if (window.scrollY >= sectionTop && window.scrollY < sectionTop + sectionHeight) {
                    currentSection = section.id;
                    return true;
                }
                
                return false;
            });
            
            setActiveSection(currentSection);
        };
        
        
        window.addEventListener('scroll', handleScroll);
        
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [scrollSpyEnabled]);
    
    const createItem = (name) => (
        <p onClick={() => {
            setScrollSpyEnabled(false);
            setActiveSection(name + '-section');
            scrollTo('.' + name);
            setTimeout(() => setScrollSpyEnabled(true), 1000);
            closeMobileNavigation();
        }}
           className={activeSection === (name + '-section') ? 'active' : null}>
            {t(name + '.title')}
        </p>
    );
    
    return (
        <div className={"menu-box navigation"}>
            {createItem('about')}
            {createItem('skills')}
            {createItem('projects')}
        </div>
    )
}

export default Navigation;