import {useEffect, useState} from "react";

function Comment({ name, content, maxLines }) {
    const [commentSize, setCommentSize] = useState(0);
    
    useEffect(() => {
        const handleResize = () => {
            const message = document.getElementById(name + "-comment-message");
            
            setCommentSize(message.offsetHeight);
        }
        
        handleResize();
        window.addEventListener('resize', handleResize);
        
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [name]);
    
    let lines = [];
    
    for (let i = 0; i < maxLines; i++) {
        lines.push(<p key={i}>//</p>);
    }
    
    return (
        <div className={"comment " + name + "-comment"} style={{height: `${commentSize}px`}}>
            <div className={"lines"}>
                {lines}
            </div>
            <div className={"message"}>
                <p id={name + "-comment-message"}>
                    {content}
                </p>
            </div>
        </div>
    )
}

export default Comment;