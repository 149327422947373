import {useData} from "../../DataContext";

import GitHubIcon from "../../assets/media/github.svg";
import LinkedinIcon from "../../assets/media/linkedin.svg";
import TwitterIcon from "../../assets/media/twitter.svg";
import MailIcon from "../../assets/media/mail.svg";

function Contact() {
    const data = useData();
    
    return (
        <div className={"contact"}>
            <a className={"contact-button"} href={data.contact.github} target="_blank">
                <img src={GitHubIcon} alt="GitHub Icon"/>
            </a>
            <a className={"contact-button"} href={data.contact.linkedin} target="_blank">
                <img src={LinkedinIcon} alt="LinkedIn Icon"/>
            </a>
            <a className={"contact-button"} href={data.contact.twitter} target="_blank">
                <img src={TwitterIcon} alt="Twitter (X) Icon"/>
            </a>
            <a className={"contact-button"} href={data.contact.mail}>
                <img src={MailIcon} alt="Mail Icon"/>
            </a>
        </div>
    )
}

export default Contact;