import DownArrow from "../../assets/media/down-arrow.svg";
import { scrollTo } from "../../utils";

function ScrollArrow() {
    return (
        <div className={"scrollDown"}>
            <img onClick={() => scrollTo('.page-body')} src={DownArrow} alt="Click to scroll"/>
        </div>
    )
}

export default ScrollArrow;