import Menu from "./Menu";
import MenuTitle from "./MenuTitle";
import {useEffect} from "react";
import Navigation from "./Navigation";
import Burger from "./Burger";

function ScrollMenu({ openMobileNavigation, closeMobileNavigation }) {
    useEffect(() => {
        const handleScroll = () => {
            const scrollMenu = document.querySelector(".menu-scroll");
            
            if (document.body.scrollTop > 100 || document.documentElement.scrollTop > 100) {
                scrollMenu.classList.add('show');
            } else {
                scrollMenu.classList.remove('show');
            }
        }
        
        handleScroll();
        window.addEventListener('scroll', handleScroll);
        
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    
    return (
        <Menu name={"scroll"}>
            <MenuTitle/>
            <Navigation closeMobileNavigation={closeMobileNavigation} />
            <Burger openMobileNavigation={openMobileNavigation} />
        </Menu>
    )
}

export default ScrollMenu;