function Avatar() {
    return (
        <div className={"avatar"}>
            <div className={"light light-1"}></div>
            <img src="memoji.png" alt=""/>
            <div className={"light light-2"}></div>
        </div>
    )
}

export default Avatar;