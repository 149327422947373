import Menu from "./Menu";
import MenuTitle from "./MenuTitle";
import Contact from "./Contact";

import ResumeIcon from "../../assets/media/resume.svg";
import Burger from "./Burger";

function HeadMenu({ openMobileNavigation }) {
    return (
        <Menu name={"head"}>
            <MenuTitle/>
            <div className={"menu-box"}>
                <Contact/>
                { /*
                   <div className={"button"}>
                    <img src={ResumeIcon} alt=""/>
                    <p>Mon CV</p>
                </div>
                */ }
            </div>
            <Burger openMobileNavigation={openMobileNavigation} />
        </Menu>
    )
}

export default HeadMenu;