import {useState} from "react";
import { scrollTo } from "../../utils";

function HeaderButton({text, color, destination}) {
    const [isIconVisible, setIsIconVisible] = useState(false);
    
    const handleClick = () => {
        if (destination !== null)
            scrollTo(destination);
    }
    
    return (
        <div
            className={`button ${color} ${isIconVisible ? 'icon-visible' : ''}`}
            onMouseEnter={() => setIsIconVisible(true)}
            onMouseLeave={() => setIsIconVisible(false)}
            onClick={handleClick}
        >
            <p>{text}</p>
            <div className={"icon"}><p>&gt;</p></div>
        </div>
    );
}

export default HeaderButton;