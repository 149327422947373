import React, { useState, useEffect } from "react";
import {useTranslation} from "react-i18next";
import ProjectCard from "./ProjectCard";
import DownArrow from "../../../assets/media/down-arrow.svg"
import projects from "../projects.json";

function ProjectCards({maxProjectDisplay, showMoreProjects}) {
    const { t } = useTranslation();
    
    let projectsToDisplay = projects.map((project, index) => {
        return index < maxProjectDisplay && (
            <ProjectCard key={index} project={project} />
        )
    }).filter(projectCard => projectCard !== false);
    
    const [numOfColumns, setNumOfColumns] = useState(3);
    useEffect(() => {
        const handleResize = () => {
            const screenWidth = window.innerWidth;
            
            if (screenWidth > 1000) {
                setNumOfColumns(3);
            } else if (screenWidth > 700) {
                setNumOfColumns(2);
            } else {
                setNumOfColumns(1);
            }
        };
        
        handleResize();
        window.addEventListener("resize", handleResize);
        
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);
    
    const columns = Array.from({ length: numOfColumns }, () => []);
    projectsToDisplay.forEach((project, index) => {
        columns[index % numOfColumns].push(project);
    });
    
    return (
        <div className={"cards"}>
            <div className={"columns"}>
                {
                    columns.map((column, colIndex) => (
                        <div className={"col"} key={colIndex}>
                            {column}
                        </div>
                    ))
                }
            </div>
            {
                projectsToDisplay.length < projects.length && (
                    <div className={"more"}>
                        <div className={"show-more"} onClick={showMoreProjects}>
                            <p>{t('projects.showMore')}</p>
                            <img src={DownArrow} alt="Click to show more"/>
                        </div>
                    </div>
                )
            }
        </div>
    )
}

export default ProjectCards;