import Section from "../common/Section";
import SectionContent from "../common/SectionContent";

function Skills() {
    return (
        <Section title={"Technologies"} name={"skills"} details={"Explorez les outils qui façonnent mon quotidien de développeur"} >
            <SectionContent>
                <div className={"rows"}>
                    <div className={"row"}>
                        <div className={"skillbox"}>
                            <div className={"title"}>
                                <h4>Back-end</h4>
                            </div>
                            <div className={"body"}>
                                <p>Java, PHP, NodeJS, .NET C#, Python, SQL, Redis, REST APIs, bots, webhooks, CLIs
                                </p>
                            </div>
                        </div>
                        <div className={"skillbox"}>
                            <div className={"title"}>
                                <h4>Front-end</h4>
                            </div>
                            <div className={"body"}>
                                <p>HTML/CSS, SASS, Bootstrap, Javascript, React, Figma</p>
                            </div>
                        </div>
                    </div>
                    <div className={"row"}>
                        <div className={"skillbox"}>
                            <div className={"title"}>
                                <h4>DevOps</h4>
                            </div>
                            <div className={"body"}>
                                <p>
                                    Git, GitHub, GitLab, Docker, Jenkins, Maven, Trello, Notion, CI/CD
                                </p>
                            </div>
                        </div>
                        <div className={"skillbox"}>
                            <div className={"title"}>
                                <h4>Autre spécialités</h4>
                            </div>
                            <div className={"body"}>
                                <p>
                                    Bukkit, Spigot, Paper, BungeeCord, Discord API, YouTube API
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </SectionContent>
        </Section>
    )
}

export default Skills;